

















































































































































































































import Vue from "vue";
import { Dialog, Field, Icon, Picker, Popup, Toast } from "vant";
import qs from "qs";

interface EbikeDeviceType {
  deviceType: number;
  text: string;
}
interface BatteryType {
  type: number;
  text: string;
}

export default Vue.extend({
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field
  },
  data() {
    return {
      platformId: 0,
      showRent: false,
      showEbike: false,
      showBattery: false,
      showCabinet: false,
      batteryCount: 0,
      batteryInEbikeCount: 0,
      batteryInRoomCount: 0,
      batteryInWhereCount: 0,
      batteryMinElectric: 0,
      batteryNoUseCount: 0,
      batteryOffLineCount: 0,
      batteryOnlineCount: 0,
      batteryUseCount: 0,
      chargeCabinetCount: 0,
      chargeCabinetDownCount: 0,
      chargeCabinetUpCount: 0,
      ebikeCount: 0,
      ebikeNoUseCount: 0,
      ebikeOfflineCount: 0,
      ebikeOnlineCount: 0,
      ebikeUseCount: 0,
      ebikeShowCount: 0,
      replaceCabinet2Count: 0,
      replaceCabinet2DownCount: 0,
      replaceCabinet2UpCount: 0,
      replaceCabinetCount: 0,
      replaceCabinetDownCount: 0,
      replaceCabinetUpCount: 0,
      ebikeDeviceTypePicker: {
        list: [
          { deviceType: 0, text: "全部车型" },
          { deviceType: 1, text: "F1" },
          { deviceType: 2, text: "F2" },
          { deviceType: 3, text: "F3" },
          { deviceType: 4, text: "F4" },
          { deviceType: 5, text: "F5" },
          { deviceType: 6, text: "F6" },
          { deviceType: 7, text: "F7" },
          { deviceType: 8, text: "F8" }
        ] as EbikeDeviceType[],
        picked: 0,
        text: "全部车型",
        show: false
      },
      batteryTypePicker: {
        list: [
          { type: 0, text: "全部类型" },
          { type: 1, text: "1代电池(48V13AH)" },
          { type: 2, text: "2代电池(48V20AH)" },
          { type: 3, text: "2代电池(60V20AH)" },
          { type: -1, text: "其他" }
        ],
        picked: 0,
        text: "全部类型",
        show: false
      },
      showExportDialog: false,
      exportEmail: ""
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios.post("/api/manage/getDeviceInfo", {}).then(e => {
        const data = e.data.data;
        Object.assign(this.$data, data);
      });
    },
    getEbikeData() {
      this.$axios.post("/api/manage/getDeviceEbikeInfo", { deviceType: this.ebikeDeviceTypePicker.picked }).then(e => {
        const data = e.data.data;
        Object.assign(this.$data, data);
      });
    },
    getBatteryData() {
      this.$axios.post("/api/manage/getDeviceBatteryInfo", { batteryType: this.batteryTypePicker.picked }).then(e => {
        const data = e.data.data;
        Object.assign(this.$data, data);
      });
    },
    onPickEbikeDeviceType(value: EbikeDeviceType) {
      this.ebikeDeviceTypePicker.picked = value.deviceType;
      this.ebikeDeviceTypePicker.text = value.text;
      this.ebikeDeviceTypePicker.show = false;
      this.getEbikeData();
    },
    onPickBatteryType(value: { type: number; text: string }) {
      this.batteryTypePicker.picked = value.type;
      this.batteryTypePicker.text = value.text;
      this.batteryTypePicker.show = false;
      this.getBatteryData();
    },
    toEbikeMap(data: Record<string, number>) {
      data.deviceType = this.ebikeDeviceTypePicker.picked;
      this.$router.push("/device/ebike-map?" + qs.stringify(data));
    },
    toBatteryMap(data: Record<string, number>) {
      data.batteryType = this.batteryTypePicker.picked;
      this.$router.push("/device/battery-map?" + qs.stringify(data));
    },
    toCabinetMap(cabinetType: number, isOnline: number) {
      // this.$toMiniAppWeb("/web/manager/manager_device_detail_switch_cabinet.html?cabinetType=" + cabinetType + "&isOnline=" + isOnline);
      const lastUrl = localStorage.lastDeviceListPage || "/web/manager/manager_device_list_switch_cabinet.html";
      this.$toMiniAppWeb(lastUrl + "?cabinetType=" + cabinetType + "&isOnline=" + isOnline);
    },
    async exportChargeCabinet() {
      if (!this.exportEmail) {
        Toast.fail("请输入邮箱");
        return;
      }
      const result = await this.$axios
        .post("/api/manage/cabinet/exportCabinet", { email: this.exportEmail, cabinetType: 0 })
        .then(() => true)
        .catch(() => false);
      if (result) {
        Toast.success("导出完成");
      }
    }
  }
});
